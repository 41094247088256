export default function useCampgroundsUrl() {
  const runtimeConfig = useRuntimeConfig()
  const { locale } = useI18n()

  const baseUrl = computed(() => `${runtimeConfig.public.apps.campgrounds.baseURL}${locale.value === 'fr' ? '/fr' : ''}`)

  function getCampgroundsUrl(slug?: string) {
    const slugPath = slug ? `/${slug}` : ''
    return `${baseUrl.value}/campgrounds${slugPath}`
  }

  function getCampgroundUrl(campgroundId: string) {
    return `${baseUrl.value}/campground/${campgroundId}`
  }

  return {
    getCampgroundsUrl,
    getCampgroundUrl,
  }
}
